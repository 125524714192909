.login-page {
    background-image: url(/public/images/login-background.svg);
    background-repeat: repeat no-repeat;
    --background-height: 350px;
    background-size: auto var(--background-height);
    background-position-y: calc(100% + calc(var(--background-height) * 0.36));
    padding: 2rem;
    padding-top: 12vh;
}

@media (max-width: 540px) {
    .login-page {
        padding: 1.5rem;
        padding-top: 2rem;
    }
}

.login-page header {
    margin-bottom: 2.5rem;
}

.login-page main {
    padding: 0;
}

.login-form {
    max-width: 720px;
    background-color: #fbfbfbe6;
    padding: 1rem;
    padding-bottom: 1.8rem;
}

.heading {
    display: flex;
    justify-content: space-between;
    gap: 25%;
    font-size: 1.7rem;
    line-height: 1.25em;
    color: #454545;
    letter-spacing: -0.6px;
    margin-bottom: 0.8em;
}

.heading img {
    height: 2.8em;
}

@media (max-width: 540px) {
    .heading img {
        display: none;
    }
}

.gradient {
    height: 15px;
    background: linear-gradient(115deg, #006BB8 30%, #0F5319 90%);
}

.login-input-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10%;
    font-size: 1.2rem;
    border-bottom: solid 1px #C3C3C3;
    margin-bottom: 1.1em;
}

.login-input-group > * {
    flex-grow: 1;
}

.login-input-group label {
    padding: 0;
    align-self: auto;
    flex-basis: 35%;
    text-wrap: nowrap;
}

.login-input-group input {
    border-bottom: none;
    height: 2.5rem;
    max-width: none;
    flex-basis: 55%;
}

.login-form-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.captcha :global(.frc-captcha) {
    max-width: none;
    border: none;
    background-color: transparent;
}

.captcha :global(.frc-captcha) * {
    font-size: 0.8rem;
}

.captcha :global(.frc-banner) * {
    color: #4160F7;
    opacity: 1;
    font-size: 0.8em;
}

.login-form-actions > * {
    flex-grow: 1;
}

.login-form-login {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.login-form-login button {
    flex-grow: 1;
}

.login-form-login button {
    width: fit-content;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    line-height: 1.4em;
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.login-form-actions button svg {
    height: 1.4rem;
    width: 1.4rem;
}
