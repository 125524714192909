.account-page {
  max-width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
}

@media (max-width: 560px) {
  .account-page {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

table.account-details,
.account-details th,
.account-details td {
  display: block;
}

.account-details {
  border: none;
}

.account-details tbody {
  display: grid;
  grid-template-columns: repeat(2, 45%);
  row-gap: 1rem;
  column-gap: 10%;
}

@media (max-width: 1080px) {
  .account-details tbody {
    grid-template-columns: 100%;
  }
}

.account-details tr {
  display: flex;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 560px) {
  .account-details tr {
    display: block;
  }
}

table.account-details tr {
  border: none;
}

.account-details tr > * {
  padding: 0.1rem 0.7rem;
  background-color: transparent;
}

.account-details tr > :first-child {
  padding-left: 0;
}

@media (max-width: 560px) {
  .account-details tr > :first-child {
    padding-left: 0.5rem;
  }
}

.account-details tr:not(.button-row) > * {
  flex-grow: 1;
  border: none;
  border-right: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  font-size: 1.4rem;
}

.account-details th {
  flex-basis: 40%;
  font-weight: 400;
  position: initial;
  text-align: left;
}

.account-details tr:not(.button-row) td {
  flex-basis: 60%;
  font-weight: 700;
}

.account-details td {
  overflow: hidden;
  text-overflow: ellipsis;
}

tr.button-row > * {
  border: none;
}

.button-row button {
  width: max-content;
}

.clipboard td {
  font-weight: 400;
}

.clipboard button {
  border: none;
  padding: 0;
  display: inline-flex;
  font-size: 1rem;
}

.clipboard button:hover {
  background-color: initial;
  color: initial;
}

.clipboard button span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.clipboard button svg {
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  top: -0.25rem;
}

.clipboard-copy-information:not(.open) {
  opacity: 0;
}

.clipboard-copy-information {
  margin-top: 2rem;
  color: #6a6a6a;
  opacity: 1;
  transition: ease-in-out 0.1s;
}
