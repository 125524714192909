.item {
    padding: 3vmin;
    border: 1px solid #454545;
    position: relative;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
}

.header > * {
  width: fit-content;
}

.headline {
    margin: 0;
    color: #707070
}

.id-string {
    margin-right: 1.5rem;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.footer > * {
  width: fit-content;
}

.link {
    text-decoration: none;
}

.button {
    display: flex;
    padding: 0.8rem;
    width: fit-content;
    text-align: center;
    background-color: #c3c3c3;
    color: #fbfbfb;
    border: none;
    border-radius: 0;
    box-shadow: none;
    align-items: center;
}

.button-text {
    margin-right: 0.8rem;
}

.primary-button {
    background-color: #006cb8;
    color: aliceblue;
    border-color: #006cb8;
}

.primary-button:hover {
    background-color: #c3c3c3;
    color: #fbfbfb;
}

.table {
    border-collapse: collapse;
}

.table td, .table th {
    border: 1px solid #C3C3C3;
}

.table tr td:first-child,
.table tr th:first-child {
    color: #454545
}

.table tr td:last-child,
.table tr th:last-child {
    color: #707070;
}

.table tr:nth-of-type(n+2) {
    border-top: solid thin #ddd;
}
