button.info-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    min-width: 44px;
}

button.info-button svg {
    width: 1rem;
    height: 1rem;
}

dialog.modal-overlay {
    position: absolute;
    background-color: white;
    z-index: 3;
    font-size: 0.9rem;
    border: none;
    padding-top: 0;
    margin-right: 0;
}

.modal-overlay label {
    font-size: 0.9rem;
    padding-left: 0;
}

.modal-overlay h2 {
    font-size: 0.95rem;
    font-weight: 700;
}

.label-with-button {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.label-with-button label {
    align-self: center;
}

.label-with-button .button-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: -1rem;
}

.label-with-button .button-container span {
    font-size: 0.8rem;
    margin-right: -1rem;
}
