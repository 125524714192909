.header {
  background-color: #444;
  color: #fbfbfb;
}

.nav-list {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  font-size: 1.5rem;
}

.nav-list a {
  text-decoration: none;
}

.nav-list button {
  border: none;
  background-color: transparent;
  color: #fbfbfb;
}

.nav-list > div > * {
  padding: 0 1rem;
}

.nav-list > div > *:not(:last-child) {
  border-right: solid 2px #ddd;
  display: inline-block;
}

.nav-list > div {
  display: flex;
}

.logout-button svg {
  position: relative;
  top: 0.25rem;
  margin-left: 0.7rem;
}
