.list_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    grid-gap: 3rem;
}

@media(max-width: 1080px) {
  .list_grid {
    grid-template-columns: 1fr;
  }
}

.list_grid li {
    padding: 3vmin;
    border: 1px solid #454545;
}

.list_grid li .headline {
    display: flex;
    justify-content: space-between;
}

.list_grid li section{
    display: flex;
    justify-content: space-between;
}

.button-preview {
    display: flex;
    justify-content: center;
    align-items: center;
}
