.color-container {
    position: relative;
    --height: 3rem;
    --swatch-size: calc(var(--height) - 0.7rem);
}

.color-container input {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--height);
}

.color-container input[type="color"]:hover {
    background-color: white !important;
}

.color-container ::-webkit-color-swatch-wrapper {
    padding: 0;
    height: var(--swatch-size);
    width: var(--swatch-size);
}

.color-container ::-webkit-color-swatch {
    border: 0;
    border-radius: calc(var(--swatch-size) / 2);
}

.color-container ::-moz-color-swatch,
.color-container ::-moz-focus-inner{
    height: var(--swatch-size);
    width: var(--swatch-size);
    border: 0;
    border-radius: calc(var(--swatch-size) / 2);
    margin-left: auto;
    margin-right: 0;
}

.color-container ::-moz-focus-inner{
    padding: 0;
}

input.manual-input:hover,
input.manual-input {
    border: none;
    background-color: transparent;
    width: 8rem;
}

.value-container {
    position: absolute;
    left: 1rem;
    z-index: 2;
    height: var(--height);
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.value-container :first-child {
    font-weight: bold;
}
