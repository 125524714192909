.item {
    display: flex;
    box-shadow: none;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    border: dashed 2px transparent;
    transition: .2s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.item:hover {
    border: dashed 2px #ddd;
}

.item:hover button:not(:hover) {
    background-color: #eee;
}

.item button {
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    flex-basis: 16em;
    padding:1.8em 2em 1.8em 1.8em;
}

.item button b {
    font-size: 3em;
    display: block;
    margin-top: -.88rem;
    margin-right: 1rem;
}

.item header,
.item section {
    z-index: 1;
}

.item header {
    padding-right: 2em;
}

.item section {
    flex-basis: 10em;
    align-items: flex-end;
}

.item table {
    margin-left: -.8rem;
}

.item table tr:nth-of-type(n+2) {
    border-top-color: #bbb;
}

.item img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: grayscale(1) contrast(1.5);
    opacity: .12;
}

.item:first-child {
    grid-column-start: 1;
    grid-column-end: -1;
    aspect-ratio: unset;
    min-height: 30vh;
}

.item:not(:first-child) button:not(:hover) {
    box-shadow: none;
    border-color: transparent;
}

.item:first-child button:not(:hover) {
    color: #006cb8;
    border-color: transparent;
}

