@font-face {
    font-family: "NeueFrutigerWorld";
    src: url("./fonts/NeueFrutigerWorld-Light.woff2") format("woff2");
    src: url("./fonts/NeueFrutigerWorld-Light.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "NeueFrutigerWorld";
    src: url("./fonts/NeueFrutigerWorld-Heavy.woff2") format("woff2");
    src: url("./fonts/NeueFrutigerWorld-Heavy.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "NeueFrutigerWorld";
    src: url("./fonts/NeueFrutigerWorld-Book.woff2") format("woff2");
    src: url("./fonts/NeueFrutigerWorld-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "NeueFrutigerWorld";
    src: url("./fonts/NeueFrutigerWorld-BookIt.woff2") format("woff2");
    src: url("./fonts/NeueFrutigerWorld-BookIt.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "NeueFrutigerWorld";
    src: url("./fonts/NeueFrutigerWorld-Bold.woff2") format("woff2");
    src: url("./fonts/NeueFrutigerWorld-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "NeueFrutigerWorld";
    src: url("./fonts/NeueFrutigerWorld-BoldIt.woff2") format("woff2");
    src: url("./fonts/NeueFrutigerWorld-BoldIt.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}

html,
body {
    margin: 0;
    padding: 0;
}

body {
    background-color: #fbfbfb;
    font-size: 105%;
    hyphens: auto;
}

#root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    font-family: "NeueFrutigerWorld", sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: normal;
    font-weight: 400;
    font-size: 105%;
    background-color: #fbfbfb;
    color: #444;
    --header-height: 90px;
}

#login {
    flex-basis: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#login .status_section {
    margin-top: .5rem;
    font-size: .9rem ;
    text-align: center;
    display: flex;
}

#login .error label {
    margin-top: .5rem;
    font-size: .7rem ;
    margin-left: 5.5rem;
    margin-right: 5.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    color: #bf1650;
}

#root * {
    box-sizing: border-box;
}

#root>header,
.content-padding,
main {
    --content-padding: 1rem;
    padding: var(--content-padding) var(--content-padding) var(--content-padding);
    margin: 0;
}

#root:not(#login)>header {
    position: sticky;
    top: 0;
    z-index: 3;
    width: 100vw;
    height: var(--header-height);
    border-bottom: solid thin #ddd;
}

header {
    flex-grow: 1;
}

section+section>header {
    margin-top: 10rem;
}

article>header {
    margin-bottom: 0;
}

#dashboard section>header {
    padding-top: 2em;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-left: -4rem;
    margin-right: -4rem;
}

aside {
    max-height: calc(100vh - 10rem);
    flex-basis: 30rem;
    overflow-y: scroll;
    border-right: solid thin #ddd;
}

#documentation aside {
    overflow: auto;
}

main {
    flex-grow: 1;
    position: relative;
    padding-bottom: 5vmin;
}

#canvas {
    max-height: calc(95vh - 8rem);
    overflow: hidden;
}

aside> :first-child,
main> :first-child {
    margin-top: 1rem;
}

#documentation main,
#dev main {
    overflow-y: scroll;
}

article+article {
    margin-top: 8rem;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    line-height: 1.3;
}

.list_dash {
    list-style-position: inside;
}

.list_dash li::marker {
    content: "– ";
}

/* in order to have square items: put the comments back in the code and remove the last to rows in .list_grid, put back aspect-ratio in
.list_grid li*/
.list_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /*grid-template-rows: auto;
    grid-column-gap: 6rem;
    grid-row-gap: 6rem; */
    grid-auto-rows: min-content;
    grid-gap: 3rem;
}

.list_grid > li {
  padding-top: 3vmin;
  padding-bottom: 3vmin;
}

.list_grid > span {
  min-height: 600px;
}

@media (max-width: 1080px) {
  .list_grid {
    grid-template-columns: 1fr;
  }
}

#img_select_options .list_grid {
    grid-template-columns: repeat(auto-fit, minmax(15vmin, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding: 0;
}

#img_select_options .list_grid li {
    box-shadow: none;
    padding: 1vmin;
}

.grid_ratio_11 {
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
}

.grid_ratio_11 li {
    aspect-ratio: 1 / 1;
}

aside li {
    margin: 0 0 .9rem;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: space-between;
}

table {
    border-collapse: collapse;
}

table td {
    vertical-align: text-top;
    padding: .2rem 1.6rem .2rem .8rem;
}
table td:first-child {
    hyphens: none;
}

main input {
    max-width: 60%;
    padding-left: .5rem;
    padding-right: .5rem;
    margin-top: 0;
    margin-bottom: 0;
    align-self: center;
}

#creator main input {
    max-width: 4em;
}

#left_row input {
    align-self: flex-start;
}

select {
    color: #444;
    background-color: transparent;
    border-color: transparent;
    outline: none;
    margin: 0;
}

select:hover {
    background-color: #f4f3f3;
}

textarea {
    resize: vertical;
}

a {
    color: inherit;
    text-decoration-color: #bbb;
    text-underline-offset: .1rem;
    text-decoration-thickness: 1px;
    position: relative;
}

#dashboard section>header {
    position: sticky;
    z-index: 2;
    top: var(--header-height);
    padding-top: 2em;
    padding-bottom: 0.1rem;
    background-color: #fbfbfb;
}

h1,
h2 {
    margin: 0 0 1rem;
    padding: 0;
    font-weight: normal;
    line-height: 1.05;
    letter-spacing: -.03em;
    word-spacing: .1em;
}

h1 {
    font-size: 2.1em;
    color: #999;
}

h2,
article h3 {
    font-size: 2.1em;
}

h3 {
    font-weight: normal;
    font-size: 1.4em;
    line-height: 1.23;
    color: #2a2a2a;
    margin: 0 0 1rem;
}

#widget_preview h3 {
    word-break: break-all;
}

#widget_preview h3 b {
    display: block;
    font-family: "NeueFrutigerWorld", sans-serif;
}

aside h3 {
    margin-top: 1rem;
}

h4 {
    font-weight: bold;
    color: #2a2a2a;
    margin: 0 0 1rem;
}

p {
    line-height: 1.5;
    max-width: 30em;
    margin: 0 0 1em 0;
}

p:last-child {
    margin-bottom: 0;
}

#app_title {
    margin-top: 2rem;
    color: #999;
}

code {
    word-break: break-all;
    display: block;
    padding: 0 0 .5rem;
}

#link-documentation {
    float: right;
}

#top_row {
    height: max-content;
    width: calc(100% - 13rem);
    margin-left: 7rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#top_row input,
#left_row input {
    max-width: 4em;
}

#top_row select,
#left_row select {
    max-width: 5em;
}

#left_row {
    height: calc(100% - 2rem - 5vmin);
    width: 5rem;
    margin-top: 2rem;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

iframe {
    border: none;
}

#img_select_trigger {
    position: absolute;
    z-index: 2;
    margin: 0;
    top: calc(14rem - 3vmin);
    right: 14rem;
}

#img_select_options {
    position: absolute;
    top: calc(12rem - 3vmin);
    right: calc(14rem - 3vmin);
    left: 40%;
    margin: 0;
    padding: 3rem 3vmin 3vmin;
    background-color: #fbfbfb;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    transition: ease-in-out .2s;
}

#logo_select_trigger {
    position: absolute;
    margin: 0;
    top: 9rem;
    right: 11rem;
}

.row {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
}

.justify_between {
    justify-content: space-between;
}

.description,
#canvas label {
    font-size: 80%;
}

.description {
    display: block;
}

.text_right {
    text-align: right;
}

.grey,
.description {
    color: #aaa;
}

.text_color {
    color: #444;
}

header a.text_color {
    font-weight: bold;
}

nav a.active {
    font-weight: bold;
}

.customizable {
    transition: ease-in-out .25s;
    animation-duration: 10s;
}

.highlight .customizable {
    animation-iteration-count: once;
    animation-name: highlight_color;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hidden {
    visibility: hidden;
    opacity: 0;
}

@keyframes highlight_color {
    0% {
        color: inherit;
        text-decoration: inherit;
        text-decoration-color: transparent;
        text-decoration-thickness: 0%;
    }

    3% {
        color: orangered;
        text-decoration: underline;
        text-decoration-color: orangered;
        text-decoration-thickness: 30%;
    }

    45% {
        color: orangered;
        text-decoration: underline;
        text-decoration-color: orangered;
        text-decoration-thickness: 30%;
    }

    100% {
        color: inherit;
        text-decoration: inherit;
        text-decoration-color: transparent;
        text-decoration-thickness: 0%;
    }
}

.form-label {
    font-weight: bold;
    width: fit-content;
}

label
[type="checkbox"],
[type="color"] {
    position: relative;
    vertical-align: middle;
    bottom: 3px;
}



/*      Buttons / interactivity
*/

.input_group {
    margin:0;
    display: flex;
    flex-flow: column nowrap;
}

.input_group:hover label {
    color: #333;
}
.input_group [type="button"]:not(.button-icon) {
    color: #333;
    width: fit-content;
}

.input_group:hover input:not(:focus),
.input_group:hover select:not(:focus),
.input_group:hover textarea:not(:focus),
[type="button"] {
    border-bottom-color: #333;
}

label,
input,
select,
textarea,
button,
:where([type="button"]),
::file-selector-button {
    padding: .5rem .5rem .5rem;
    font-family: "NeueFrutigerWorld", sans-serif;
    font-size: 100%;
    line-height: 1.2;
    transition: ease-in-out .15s;
    width: stretch;
    width: -webkit-fill-available;
    width: -moz-available;
    text-decoration: none;
}

label {
    color: #666;
    align-self: flex-start;
}

label,
[type="button"],
[type="reset"],
[type="submit"],
[type="color"],
button {
    font-size: 86%;
}

input,
select,
textarea {
    border-color: #C3C3C3;
    border-width: thin;
    border-style: solid;
    box-sizing: content-box;
    align-self: flex-start;
    background-color: #fff;
    min-height: 1.5rem;
}

input:focus,
select:focus,
textarea:focus {
    border-bottom-color: #006cb8;
    outline: none;
}

input:where(.color-picker) {
    margin: .1rem;
    height: 4rem;
}


:where([type="button"]),
[type="reset"],
[type="submit"],
[type="color"],
button {
    transition: .2s cubic-bezier(0.215, 0.610, 0.355, 1);
    border-color: #ccc;
    background-color: #fbfbfb;
    border-width: thin;
    border-style: solid;
    text-align: left;
    cursor: pointer;
    color: #666;
    hyphens: none;
}

:where([type="button"]):hover:not(.button-icon),
[type="reset"]:hover:not(.button-icon),
[type="submit"]:hover:not(.button-icon),
[type="color"]:hover:not(.button-icon),
:where(button):hover:not(.button-icon) {
    background-color: #006cb8;
    color: aliceblue;
    border-color: #006cb8;
}

button.button-icon {
    background-color: transparent;
    width: fit-content;
    padding: 0;
    border: none;
    box-shadow: none;
    cursor: pointer;
    color: #666;
}

.button-primary {
  background-color: #006cb8;
  color: #fff;
  outline-offset: 1px;
}

.button-primary:focus-visible,
.button-primary:hover {
  background-color: #338ccc;
}

[type="radio"],
[type="checkbox"] {
    margin: .8rem .8rem .6rem;
    width: max-content;
}

textarea {
    resize: vertical;
}

.row label {
    align-self: stretch;
}

.row.input_group {
    flex-direction: row;
}

.button_group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 0.1em;
    position: sticky;
    z-index: 2;
    bottom: 0;
    background-color: #fbfbfb;
    margin-left: calc(var(--content-padding) * -1);
    margin-right: calc(var(--content-padding) * -1);
    padding: var(--content-padding);
}

.button_group button {
    padding: 0 1rem;
    margin: 0.1em;
    width: fit-content;
    text-align: center;
    background-color: #c3c3c3;
    color: #fbfbfb;
    border: none;
    line-height: 2em;
    font-size: 1.2rem;
}

.button_group button:hover {
    background-color: #2e6bb1;
}

.button_group button.reset:hover {
    background-color: #e6000d;
}


/*      Modules/Components
*/

/*      Button Widget Preview
*/

#button_preview li:not(:last-child) {
    position: relative;
    overflow: hidden;
}

#button_preview li:last-child {
    display: flex;
    box-shadow: none;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    border: dashed 2px transparent;
    transition: .2s cubic-bezier(0.215, 0.610, 0.355, 1);
}
#button_preview li:last-child:hover {
    border: dashed 2px #ddd;
}
#button_preview li:last-child:hover button:not(:hover) {
    background-color: #eee;
}
#button_preview li:last-child button {
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    flex-basis: 16em;
    padding:1.8em 2em 1.8em 1.8em;
}
#button_preview li:last-child button b {
    font-size: 3em;
    display: block;
    margin-top: -.88rem;
    margin-right: 1rem;
}
#button_preview li:first-child:last-child {
    grid-column-start: 1;
    grid-column-end: -1;
    aspect-ratio: unset;
    min-height: 30vh;
}
#button_preview li:last-child:not(:first-child) button:not(:hover) {
    box-shadow: none;
    border-color: transparent;
}
#button_preview li:first-child:last-child button:not(:hover) {
    color: #006cb8;
    border-color: transparent;
}

#button_preview header,
#button_preview section {
    z-index: 1;
}
#button_preview section {
    flex-basis: 2em;
    align-items: flex-end;
}

#button_preview table tr:nth-of-type(n+2) {
    border-top-color: #bbb;
}

#button_preview img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: grayscale(1) contrast(1.5);
    opacity: .12;
}


/*      media
*/

[class^="icon"] {
    position: relative;
    filter:grayscale(1) contrast(.001);
    opacity: .5;
    display: inline;
    margin-left: -.15rem;
}
[type]:hover [class^="icon"] {
    filter: grayscale(1) contrast(3) invert(1);
    opacity: 1;
}
[class^="icon"]::before {
    content: "";
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: .7rem;
    margin-bottom: -.055rem;
}
.icon_edit::before {
    background-image: url(/src/icon_edit.svg);
}
.icon_delete::before {
    background-image: url(/src/icon_delete.svg);
}


/* source code listings */
pre {
    background: #f4f4f4;
    border: 3px solid #ddd;
    /* border-left: 3px solid #f36d33; */
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}

/* modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.25s, opacity 0.25s;
  }

  .modal.show {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .modal button {
    margin-left: 10px;
  }

/*addUserForm*/


.users table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
}

.users table th, table td {
    text-align: left;
    padding: 16px;
    border: 1px solid #ddd;
}

.users table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.users table tr:hover {
    background-color: #ddd;
}

.users table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #006cb8;
    color: white;
}

.addUserForm{
    visibility: hidden;
}
.addUserForm.show{
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
}

.addUserFormContainer{
    display:flex;

}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputContainer input{
    width: 200px;
}
.inputContainer input.emailfield{
    width: 500px;
}
.inputContainer button{
    width: 200px;
}
.inputContainer #cancel_button:hover{
        background-color: red;
}


/*help page*/

ul.help_page{
    list-style-type: disc;
    padding-left: 20px;
}

ul.help_page li{
    margin-bottom: 20px;
}

.image_row{
    display: flex;
    max-width: 100%;
    max-height: 120px;
}

.image_row img {
    max-width: 200px;
    max-height: 100%;
    margin: 10px;
}

.image_row_big{
    display: flex;
    max-width: 100%;
    max-height: 300px;
}
.image_row_big img {
    max-width: 800px;
    max-height: 100%;
    margin: 10px;
}
