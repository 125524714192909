.form-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.5rem;
    position: relative;
}

.form-grid > * {
    grid-column: 1 / 3;
}

.form-grid > :global(.row) {
    display: contents !important;
    color: red;
    margin: 0.5rem 0;
}

.form-grid > :global(.row) input {
    margin: 0;
}

.form-grid > :global(.row) label {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.form-grid > :global(.row) label {
    align-self: auto;
}
