.menu {
  position: relative;
}

.menu:not(.open) ul {
  height: 0;
  overflow: hidden;
}

.menu ul {
  color: #444;
  position: absolute;
  z-index: 1;
  width: max-content;
  background-color: #fff;
}

.menu.open ul {
  border: solid 1px #ddd;
}

.menu li {
  cursor: pointer;
}

.menu li {
  padding: 0.5rem;
}

.menu ul li:hover,
.menu ul:focus-visible .active {
  background-color: #eee;
}

.menu ul li[aria-checked="true"] {
  font-weight: 700;
}

.menu ul li[aria-disabled="true"] {
  color: #afafaf;
}
